import React from "react";
import "./Section1.css";
// import charityLogo from "../images/charity-logo.png";

function Section1() {
  const handleClick = () => {
    window.open(
      "https://donor.southtexasblood.org/donor/store/preview?utm_source=double_my_impact&utm_medium=landing_page&utm_campaign=twa_dontate&utm_content=stb&t",
      "_blan"
    );
  };
  return (
    <>
      <div className="main-container">
        <div className="main-content">
          {/* <img
            src={charityLogo}
            alt="Animal Defense logo"
            className="charity-logo"
          /> */}
          <div className="main-title">
          HELP FIGHT  <br/>HUNGER
          </div>
          <div className="main-p">
            <strong>Double Your Impact,</strong> and transform 100 Reward Store points into 25 meals for the needy in South Texas.
          </div>
          <button
            className="donate-btn1 secondary-donate-btn"
            onClick={handleClick}
          >
            Donate points
          </button>
        </div>
      </div>
    </>
  );
}

export default Section1;
