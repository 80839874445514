import React from "react";
import "./Section3.css";
import joyLogo from "../images/joy-logo.png";

function Section3() {
  const campClick = () => {
    window.open("https://safoodbank.org/", "_blank");
  };
  return (
    <div className="joy-container">
      <div className="joy-image"></div>
      <div className="joy-day-container">
        <div className="sec3-text-container">
          <img src={joyLogo} alt="Animal Defense Logo" className="joy-logo" />
          <div className="joy-p">
          The San Antonio Food Bank takes pride in fighting hunger 
          and feeding hope. Founded in 1980, The San Antonio Food Bank has quickly grown to serve 
          100,000 individuals a week in one of the largest service areas in Texas.
          </div>
        </div>
        <button
          className="secondary-donate-btn special-btn"
          onClick={campClick}
        >
          FIND OUT MORE
        </button>
      </div>
    </div>
  );
}

export default Section3;
