import React from "react";
import "./Section2.css";
import section2Image from "../images/pic1.png";

function Section2() {
  const handleClick = () => {
    window.open(
      "https://donor.southtexasblood.org/donor/store/preview?utm_source=double_my_impact&utm_medium=landing_page&utm_campaign=twa_dontate&utm_content=stb&t",
      "_blan"
    );
  };
  return (
    <div className="camp-container">
      <div className="camp-title color-text">Help us Reach Our Goal</div>
      <div className="subtitle">
        In partnership with the {""}
        <strong>
         San Antonio Food Bank,
        </strong>{" "}
        we want to raise enough money to feed <strong>
          300,000 South Texans.
          </strong>
        
      </div>
      <div className="progress"></div>
      {/* <div className="color-text2 color-text">
      MEALS FOR SOUTH TEXANS
      </div> */}
      <div className="goal-2">
      MEALS FOR SOUTH TEXANS
      </div>

      <div className="flex-container">
        <div className="left-content">
          <div className="q1">How do I Donate?</div>
          {/* <div className="q1-image"></div> */}
          <div className="a1">
          Donating is easy. Log into the South Texas Blood & Tissue online store and select the
          Double Your Impact San Antonio Food Bank icon to donate a 100 points. 
          You can donate as many times as you like. Please help.
          </div>
          <button className="donate-btn2 donate-btn" onClick={handleClick}>
            Donate points
          </button>
        </div>
        <div className="image-container">
          <img src={section2Image} alt="Section 2 Image" />
        </div>
      </div>
    </div>
  );
}

export default Section2;
