import React from "react";
import "./Section4.css";
import Image3 from "../images/pic3.png";

function Section4() {
  const handleClick = () => {
    window.open(
      "https://donor.southtexasblood.org/donor/store/preview?utm_source=double_my_impact&utm_medium=landing_page&utm_campaign=twa_dontate&utm_content=stb&t",
      "_blan"
    );
  };
  return (
    <div className="climb-container">
      <div className="image3-container">
        <img src={Image3} alt="Section 3 Image" />
      </div>
      <div className="climb-content">
        <div className="climb-title">
        20% of Texas Children Experience Hunger
          {/* <span className="color-text">They are our future!”</span> */}
        </div>
        <div className="climb-p">
        Economic instability is often the main reason for hunger. 
        When a family’s income is insufficient to meet the rising costs 
        of daily life, and they encounter unforeseen 
        expenses such as vehicle accidents or health crises, 
        they may be faced with the difficult decision of prioritizing 
        food over other essential needs.
        </div>
        <button className="donate-btn3 donate-btn" onClick={handleClick}>
          Donate Points
        </button>
      </div>
    </div>
  );
}

export default Section4;
